import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  DialogContentText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import apiClient from "../api/apiClient";

const roles = ["admin", "user"];
const statuses = ["pre_screened", "registered", "suspended"];

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [email, setEmail] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await apiClient.get("/organizations/");
        setOrganizations(response.data);
      } catch (error) {
        console.error("Failed to fetch organizations:", error);
      }
    };

    fetchAllUsers();
    fetchOrganizations();
  }, []);

  const fetchAllUsers = async () => {
    try {
      const response = await apiClient.get("/admin/users/");
      setUsers(response.data);
    } catch (error) {
      console.error("Failed to fetch users:", error);
      alert("Failed to fetch users. Please try again.");
    }
  };

  const fetchUserByEmail = async () => {
    if (!email.trim()) {
      alert("Please enter an email address to search.");
      return;
    }
    try {
      const response = await apiClient.get(`/admin/user/${email.trim()}`);
      setUsers(response.data ? [response.data] : []);
    } catch (error) {
      console.error("Failed to fetch user by email:", error);
      alert("Failed to fetch user. Please try again.");
    }
  };
  const preRegisterUser = async () => {
    if (!newUserEmail.trim()) {
      alert("Please enter a valid email address.");
      return;
    }
    if (!selectedOrganizationId) {
      alert("Please select an organization.");
      return;
    }

    try {
      await apiClient.post("/admin/pre-register-user", {
        email: newUserEmail.trim(),
        organization_id: selectedOrganizationId,
      });
      setNewUserEmail("");
      setSelectedOrganizationId("");
      fetchAllUsers();
    } catch (error) {
      console.error("Failed to pre-register user:", error);
      alert("Failed to pre-register user. Please try again.");
    }
  };

  const deleteUser = async (userId) => {
    try {
      await apiClient.delete(`/admin/delete-user/${userId}`);
      fetchAllUsers(); // Refresh the list of users after deletion
    } catch (error) {
      console.error("Failed to delete user:", error);
    }
  };

  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (userToDelete) {
      deleteUser(userToDelete.id);
      setUserToDelete(null);
      setDeleteDialogOpen(false);
    }
  };

  const handleEditUser = async (updatedData) => {
    if (!editUser || !editUser.email) {
      console.error("No user selected for editing.");
      return;
    }

    if (
      !updatedData.organization_id ||
      !updatedData.role ||
      !updatedData.status
    ) {
      alert("Please fill in all fields.");
      return;
    }

    try {
      await apiClient.patch(`/admin/user/${editUser.email}`, updatedData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      fetchAllUsers();
      handleCloseEditDialog();
    } catch (error) {
      console.error(
        "Failed to update user:",
        error.response?.data || error.message
      );
      alert("Failed to update user. Please try again.");
    }
  };

  const handleOpenEditDialog = (user) => {
    setEditUser(user);
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setEditUser(null);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6">User Management</Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, my: 2 }}>
        <TextField
          className="myTextField"
          label="Search User by Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button variant="contained" onClick={fetchUserByEmail}>
          Search
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setEmail(""); // Clear the search input
            fetchAllUsers(); // Fetch all users again
          }}
        >
          Clear
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          marginBottom: 2,
          "@media (min-width: 600px)": { flexDirection: "row" },
        }}
      >
        <TextField
          className="myTextField"
          label="New User Email"
          variant="outlined"
          value={newUserEmail}
          onChange={(e) => setNewUserEmail(e.target.value)}
        />
        <FormControl variant="outlined" sx={{ minWidth: 220 }}>
          <InputLabel>Organization</InputLabel>
          <Select
            style={{
              backgroundColor: "#444",
              color: "darkgrey",
              fontSize: "14px",
              borderRadius: "4px",
            }}
            value={selectedOrganizationId}
            onChange={(e) => setSelectedOrganizationId(e.target.value)}
            label="Organization"
          >
            {organizations.map((org) => (
              <MenuItem key={org.id} value={org.id}>
                {org.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton
          color="primary"
          onClick={preRegisterUser}
          disabled={!newUserEmail.trim() || !selectedOrganizationId}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </Box>
      {/* Edit User Dialog */}
      <Dialog open={editDialogOpen} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <TextField
              margin="dense"
              label="Organization ID"
              type="number"
              fullWidth
              variant="outlined"
              value={editUser?.organization_id || ""}
              onChange={(e) =>
                setEditUser({ ...editUser, organization_id: e.target.value })
              }
              error={!editUser?.organization_id}
              helperText={
                !editUser?.organization_id ? "Organization ID is required" : ""
              }
            />
          </FormControl>

          <FormControl fullWidth margin="normal" error={!editUser?.role}>
            <InputLabel>Role</InputLabel>
            <Select
              value={editUser?.role || ""}
              onChange={(e) =>
                setEditUser({ ...editUser, role: e.target.value })
              }
              label="Role"
            >
              {roles.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal" error={!editUser?.status}>
            <InputLabel>Status</InputLabel>
            <Select
              value={editUser?.status || ""}
              onChange={(e) =>
                setEditUser({ ...editUser, status: e.target.value })
              }
              label="Status"
            >
              {statuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
          <Button
            onClick={() =>
              handleEditUser({
                organization_id: parseInt(editUser?.organization_id, 10),
                role: editUser?.role,
                status: editUser?.status,
              })
            }
            disabled={
              !editUser?.organization_id || !editUser?.role || !editUser?.status
            }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <List>
        {users.map((user) => (
          <ListItem key={user.id} divider>
            <ListItemText
              primary={`Email: ${user.email}${
                user.username ? `, Username: ${user.username}` : ""
              }`}
              secondary={`Role: ${user.role}, Status: ${user.status}${
                user.organization_id
                  ? `, Organization ID: ${user.organization_id}`
                  : ""
              }${user.full_name ? `, Full Name: ${user.full_name}` : ""}${
                user.date_joined
                  ? `, Date Joined: ${new Date(
                      user.date_joined
                    ).toLocaleDateString()}`
                  : ""
              }`}
              sx={{
                ".MuiListItemText-primary": { color: "white" }, // Adjust primary text color
                ".MuiListItemText-secondary": { color: "grey" }, // Adjust secondary text color
              }}
            />
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={() => handleOpenEditDialog(user)}
              sx={{
                color: "darkgrey", // Adjust icon color
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => handleDeleteClick(user)}
              sx={{
                color: "darkgrey", // Adjust icon color
              }}
            >
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
        <Dialog
          open={deleteDialogOpen}
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm Deletion"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this user? This action cannot be
              undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
            <Button onClick={handleConfirmDelete} autoFocus color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </List>
    </Box>
  );
};

export default UserManagement;