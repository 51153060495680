import React, { useState, useEffect, useCallback } from "react";
import { getTopStatistics, getUsers } from "../api/apiCalls.js";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Tooltip,
  Alert,
  Pagination,
  CircularProgress,
} from "@mui/material";
import TimeRangeSelector from "../components/TimeRangeSelector";
import TopStatisticsSection from "../components/TopStatisticsSection";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { getMetricsSummary, getMetricsRecords } from "../api/apiCalls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";

const MetricsManagement = () => {
  const [metrics, setMetrics] = useState([]);
  const [summary, setSummary] = useState({
    total_cost: 0,
    total_tokens: 0,
    total_calls: 0,
    operation_counts: {},
    model_usage: {},
  });
  const [filters, setFilters] = useState({
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), // 7 days ago
    endDate: new Date(), // current date
    operationType: "all",
    organizationId: "all",
    user: "all",
  });
  const [users, setUsers] = useState(["all"]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(10);
  const [topStats, setTopStats] = useState(null);

  // Date validation
  const validateDateRange = useCallback(() => {
    if (filters.startDate && filters.endDate) {
      if (filters.startDate > filters.endDate) {
        setError("Start date must be before end date");
        return false;
      }
    }
    return true;
  }, [filters.startDate, filters.endDate]);

  // Helper function to format timestamps in 12-hour format
  const formatLocalTime = (timestamp) => {
    try {
      // Create a date object from the UTC timestamp
      const utcDate = new Date(timestamp);

      // Explicitly convert to Manila time by adding 8 hours worth of milliseconds
      const manilaDate = new Date(utcDate.getTime() + 8 * 60 * 60 * 1000);

      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      };

      return manilaDate.toLocaleString("en-US", options);
    } catch (error) {
      console.error("Error formatting time:", error);
      return timestamp;
    }
  };

  // Fetch users list
  const fetchUsers = useCallback(async () => {
    try {
      const usersList = await getUsers();
      console.log("Fetched users:", usersList); // Debug log

      // Filter active users and transform to the format we need
      const activeUsers = usersList
        .filter((user) => user.status === "registered")
        .map((user) => user.email);

      setUsers(["all", ...activeUsers]);
    } catch (err) {
      console.error("Error fetching users:", err);
      setError("Failed to fetch users list");
    }
  }, []);

  // Fetch metrics data
  const fetchData = useCallback(async () => {
    if (!validateDateRange()) return;
  
    setLoading(true);
    setError(null);
    try {
      // Ensure dates are in ISO format
      const startDateISO = filters.startDate?.toISOString();
      const endDateISO = filters.endDate?.toISOString();
  
      const [summaryData, recordsData, topStatsData] = await Promise.all([
        getMetricsSummary({
          organizationId: filters.organizationId,
          startDate: startDateISO,
          endDate: endDateISO,
        }),
        getMetricsRecords({
          ...filters,
          startDate: startDateISO,
          endDate: endDateISO,
          user: filters.user,
          page,
          pageSize,
        }),
        getTopStatistics({
          startDate: startDateISO,
          endDate: endDateISO,
        })
      ]);
  
      setSummary(summaryData);
      setMetrics(recordsData.records);
      setTotalPages(recordsData.total_pages);
      setTopStats(topStatsData);
    } catch (err) {
      setError(err.response?.data?.detail || "Failed to fetch metrics data");
    } finally {
      setLoading(false);
    }
  }, [filters, page, pageSize, validateDateRange]);
  
  // Initial data fetch
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  // Fetch data when filters or pagination changes
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Handle filter changes
  const handleFilterChange = (field) => (event) => {
    const newValue = event.target.value;
    console.log(`Changing ${field} filter to:`, newValue); // Debug log
    setFilters((prev) => ({
      ...prev,
      [field]: newValue,
    }));
    setPage(1);
  };

  // Handle pagination
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Export to CSV
  const handleExport = () => {
    const headers = [
      "Time",
      "User",
      "Task",
      "Model",
      "Input Tokens",
      "Output Tokens",
      "Cost (PHP)",
    ];

    const csvData = metrics.map((row) => [
      formatLocalTime(row.timestamp),
      row.user_email,
      row.task_name,
      row.model_used,
      row.metrics_data?.prompt_tokens || "",
      row.metrics_data?.completion_tokens || "",
      row.cost_php.toFixed(2),
    ]);

    const csvContent = [
      headers.join(","),
      ...csvData.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `metrics_export_${formatLocalTime(new Date())}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ p: 2 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {/* Header */}
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <Typography variant="h6">Usage Metrics</Typography>
          <Box>
            <Tooltip title="Refresh">
              <IconButton
                sx={{ color: "#888", marginRight: "-10px" }}
                onClick={fetchData}
                disabled={loading}
              >
                <FontAwesomeIcon
                  icon={faArrowsRotate}
                  className="icon-button"
                  spin={loading}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Export CSV">
              <IconButton
                onClick={handleExport}
                disabled={loading || metrics.length === 0}
                sx={{ color: "#888" }}
              >
                <FontAwesomeIcon icon={faFloppyDisk} className="icon-button" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        {/* Stats Cards */}
        <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
          <Paper sx={{ p: 2, flex: 1, bgcolor: "#444", color: "white" }}>
            <Typography variant="subtitle2" sx={{ color: "inherit" }}>
              Total Cost (PHP)
            </Typography>
            <Typography variant="h4" sx={{ color: "inherit" }}>
              {summary.total_cost.toFixed(2)}
            </Typography>
          </Paper>
          <Paper sx={{ p: 2, flex: 1, bgcolor: "#444", color: "white" }}>
            <Typography variant="subtitle2" sx={{ color: "inherit" }}>
              Total Tokens
            </Typography>
            <Typography variant="h4" sx={{ color: "inherit" }}>
              {summary.total_tokens.toLocaleString()}
            </Typography>
          </Paper>
          <Paper sx={{ p: 2, flex: 1, bgcolor: "#444", color: "white" }}>
            <Typography variant="subtitle2" sx={{ color: "inherit" }}>
              API Calls
            </Typography>
            <Typography variant="h4" sx={{ color: "inherit" }}>
              {summary.total_calls.toLocaleString()}
            </Typography>
          </Paper>
        </Box>

        {/* Filters */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 3 }}>
          <TimeRangeSelector
            startDate={filters.startDate}
            endDate={filters.endDate}
            onChange={({ startDate, endDate }) => {
              setFilters((prev) => ({
                ...prev,
                startDate,
                endDate,
              }));
              setPage(1);
            }}
          />

          <Box sx={{ display: "flex", gap: 2 }}>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel sx={{ color: "white" }}>Operation Type</InputLabel>
              <Select
                value={filters.operationType}
                onChange={handleFilterChange("operationType")}
                label="Operation Type"
                sx={{ bgcolor: "#444", color: "white" }}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="llm">LLM</MenuItem>
                <MenuItem value="image">Image</MenuItem>
                <MenuItem value="audio">Audio</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel sx={{ color: "white" }}>User</InputLabel>
              <Select
                value={filters.user}
                onChange={handleFilterChange("user")}
                label="User"
                sx={{ bgcolor: "#444", color: "white" }}
              >
                {users.map((user) => (
                  <MenuItem key={user} value={user}>
                    {user === "all" ? "All Users" : user}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {/* Table */}
        <TableContainer component={Paper} sx={{ bgcolor: "#444" }}>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: "#999", fontWeight: "bold" }}>
                      Time
                    </TableCell>
                    <TableCell sx={{ color: "#999", fontWeight: "bold" }}>
                      User
                    </TableCell>
                    <TableCell sx={{ color: "#999", fontWeight: "bold" }}>
                      Task
                    </TableCell>
                    <TableCell sx={{ color: "#999", fontWeight: "bold" }}>
                      Model
                    </TableCell>
                    <TableCell
                      sx={{ color: "#999", fontWeight: "bold" }}
                      align="right"
                    >
                      Input Tokens
                    </TableCell>
                    <TableCell
                      sx={{ color: "#999", fontWeight: "bold" }}
                      align="right"
                    >
                      Output Tokens
                    </TableCell>
                    <TableCell
                      sx={{ color: "#999", fontWeight: "bold" }}
                      align="right"
                    >
                      Cost (PHP)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metrics.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        bgcolor: "#333",
                        "&:nth-of-type(odd)": { bgcolor: "#444" },
                        "&:hover": { bgcolor: "#555" },
                      }}
                    >
                      <TableCell sx={{ color: "#999" }}>
                        {formatLocalTime(row.timestamp)}
                      </TableCell>
                      <TableCell sx={{ color: "#999" }}>
                        {row.user_email}
                      </TableCell>
                      <TableCell sx={{ color: "#999" }}>
                        {row.task_name}
                      </TableCell>
                      <TableCell sx={{ color: "#999" }}>
                        {row.model_used}
                      </TableCell>
                      <TableCell sx={{ color: "#999" }} align="right">
                        {row.metrics_data?.prompt_tokens || "-"}
                      </TableCell>
                      <TableCell sx={{ color: "#999" }} align="right">
                        {row.metrics_data?.completion_tokens || "-"}
                      </TableCell>
                      <TableCell sx={{ color: "#999" }} align="right">
                        {row.cost_php.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Box>
            </>
          )}
        </TableContainer>
        <Box sx={{marginTop: "30px"}}>
          {" "}
          <TopStatisticsSection topStats={topStats} />{" "}
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default MetricsManagement;