import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faPaste,
  faFloppyDisk,
  faTrashCan,
} from "@fortawesome/free-regular-svg-icons";
import {
  faWandMagicSparkles,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import SaveFileModal from '../components/SaveFileModal';

const IconToolbar = ({
  isImagePromptBox,
  isPromptBox,
  isGenerating,
  customGenerateButton,
  handleTextClear,
  onGenerateClick,
  handleSaveClick,
  handleCopyClick,
  handlePasteClick,
  readOnly,
  onChange,
  context,
  showSaveIcon,
  textAreaRef,
  textValue,
  customButton,
  isBasicToolbar = false,
}) => {
  const [clickedIcon, setClickedIcon] = useState({
    clear: false,
    save: false,
    copy: false,
    paste: false,
  });
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [filename, setFilename] = useState('');

  useEffect(() => {
    if (saveModalOpen) {
      const timestamp = new Date().toISOString().replace(/[:]/g, '-').split('.')[0];
      let baseFilename;
      
      if (context === "WriteTab") {
        baseFilename = `MakGPT_Write_Session`;
      } else if (context === "SummarizeTab") {
        baseFilename = `MakGPT_Summarize_Articles_Session`;
      } else {
        baseFilename = `MakGPT_Session`;
      }
      
      const defaultFilename = `${baseFilename}_${timestamp}`;
      setFilename(defaultFilename);
    }
  }, [saveModalOpen, context]);

  const highlightIcon = (iconName) => {
    setClickedIcon({ ...clickedIcon, [iconName]: true });
    setTimeout(
      () => setClickedIcon({ ...clickedIcon, [iconName]: false }),
      1000
    );
  };

  const handleSave = () => {
    const blob = new Blob([textValue], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${filename || 'MakGPT_Session'}.txt`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    setSaveModalOpen(false);
    setFilename('');
    
    if (handleSaveClick) handleSaveClick();
  };

  const handleClearClick = () => {
    highlightIcon("clear");
    if (handleTextClear) {
      handleTextClear();
    }
  };

  const copyToClipboard = () => {
    const textarea = textAreaRef.current;
    if (textarea) {
      const textToCopy =
        textarea.selectionStart !== textarea.selectionEnd
          ? textarea.value.substring(
              textarea.selectionStart,
              textarea.selectionEnd
            )
          : textValue;
      navigator.clipboard.writeText(textToCopy);
    }
  };

  const pasteFromClipboard = async () => {
    if (navigator.clipboard && navigator.clipboard.readText) {
      const text = await navigator.clipboard.readText();
      const textarea = textAreaRef.current;
      if (textarea && !readOnly) {
        const startPos = textarea.selectionStart;
        const endPos = textarea.selectionEnd;
        const before = textValue.substring(0, startPos);
        const after = textValue.substring(endPos);
        const newValue = before + text + after;

        onChange({ target: { value: newValue } });

        setTimeout(() => {
          textarea.selectionStart = textarea.selectionEnd = startPos + text.length;
        }, 0);
      }
    }
  };

  const renderBasicIcons = () => (
    <>
      <Tooltip title="Clear">
        <FontAwesomeIcon
          icon={faTrashCan}
          className={`icon-button ${clickedIcon.clear ? "highlighted" : ""}`}
          onClick={() => {
            highlightIcon("clear");
            handleTextClear?.();
          }}
        />
      </Tooltip>
      <Tooltip title="Copy">
        <FontAwesomeIcon
          icon={faCopy}
          className={`icon-button ${clickedIcon.copy ? "highlighted" : ""}`}
          onClick={() => {
            highlightIcon("copy");
            copyToClipboard();
            handleCopyClick?.();
          }}
        />
      </Tooltip>
      {!readOnly && (
        <Tooltip title="Paste">
          <FontAwesomeIcon
            icon={faPaste}
            className={`icon-button ${clickedIcon.paste ? "highlighted" : ""}`}
            onClick={() => {
              highlightIcon("paste");
              pasteFromClipboard();
              handlePasteClick?.();
            }}
          />
        </Tooltip>
      )}
    </>
  );

  const renderIcons = () => {
    return (
      <>
        {customButton && (
          <span className="custom-button-container">{customButton}</span>
        )}
        {isImagePromptBox && (
          <>
            <Tooltip title="Clear">
              <FontAwesomeIcon
                icon={faTrashCan}
                className={`icon-button ${clickedIcon.clear ? "highlighted" : ""}`}
                onClick={handleClearClick}
              />
            </Tooltip>
          </>
        )}
        {isPromptBox && (
          <>
            {customGenerateButton || (
              <FontAwesomeIcon
                icon={isGenerating ? faSpinner : faWandMagicSparkles}
                className="icon-button-generate"
                onClick={isGenerating ? null : onGenerateClick}
                spin={isGenerating}
              />
            )}
            <Tooltip title="Clear">
              <FontAwesomeIcon
                icon={faTrashCan}
                className={`icon-button ${clickedIcon.clear ? "highlighted" : ""}`}
                onClick={handleClearClick}
              />
            </Tooltip>
          </>
        )}
        {showSaveIcon && (
          <>
            <Tooltip title="Save as text file">
              <FontAwesomeIcon
                icon={faFloppyDisk}
                className={`icon-button ${clickedIcon.save ? "highlighted" : ""}`}
                onClick={() => {
                  highlightIcon("save");
                  setSaveModalOpen(true);
                }}
              />
            </Tooltip>
            <SaveFileModal
              open={saveModalOpen}
              onClose={() => {
                setSaveModalOpen(false);
                setFilename('');
              }}
              onSave={handleSave}
              title="Save Session"
              filename={filename}
              onFilenameChange={(e) => setFilename(e.target.value)}
              placeholder="session_export"
              helperText=".txt will be automatically added"
              fileExtension=".txt"
            />
          </>
        )}
        <Tooltip title="Copy">
          <FontAwesomeIcon
            icon={faCopy}
            className={`icon-button ${clickedIcon.copy ? "highlighted" : ""}`}
            onClick={() => {
              highlightIcon("copy");
              copyToClipboard();
              if (handleCopyClick) handleCopyClick();
            }}
          />
        </Tooltip>
        {!readOnly && (
          <Tooltip title="Paste">
            <FontAwesomeIcon
              icon={faPaste}
              className={`icon-button ${clickedIcon.paste ? "highlighted" : ""}`}
              onClick={() => {
                highlightIcon("paste");
                pasteFromClipboard();
                if (handlePasteClick) handlePasteClick();
              }}
            />
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <div className="icon-container">
      {isBasicToolbar ? renderBasicIcons() : renderIcons()}
    </div>
  );
};

export default IconToolbar;