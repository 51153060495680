import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Fade } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const AdaptiveChatContainer = ({ children, messages }) => {
  const containerRef = useRef(null);
  const [isNarrowViewport, setIsNarrowViewport] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [containerHeight, setContainerHeight] = useState('400px');
  
  // Check viewport width and update responsive state
  useEffect(() => {
    const checkViewportWidth = () => {
      setIsNarrowViewport(window.innerWidth < 768);
    };
    
    checkViewportWidth();
    window.addEventListener('resize', checkViewportWidth);
    return () => window.removeEventListener('resize', checkViewportWidth);
  }, []);

  // Height and scroll handling
  useEffect(() => {
    const containerElement = containerRef.current;
    if (!containerElement) return;

    const updateHeightAndScroll = () => {
      const vh = window.innerHeight;
      const containerTop = containerElement.getBoundingClientRect().top;
      const availableHeight = vh - containerTop - (isNarrowViewport ? 24 : 16);
      
      setContainerHeight(`${Math.max(400, availableHeight)}px`);
      
      // Check scroll position for button visibility
      const { scrollTop, scrollHeight, clientHeight } = containerElement;
      setShowScrollButton(scrollTop < scrollHeight - clientHeight - 100);
    };

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = containerElement;
      setShowScrollButton(scrollTop < scrollHeight - clientHeight - 100);
    };

    updateHeightAndScroll();
    window.addEventListener('resize', updateHeightAndScroll);
    containerElement.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('resize', updateHeightAndScroll);
      containerElement.removeEventListener('scroll', handleScroll);
    };
  }, [isNarrowViewport]);

  // Handle first message appearance
  useEffect(() => {
    if (messages?.length === 1) {
      setTimeout(() => {
        containerRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, 100);
    }
  }, [messages]);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div 
      ref={containerRef}
      className="chat-container"
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: containerHeight,
        minHeight: '400px',
        transition: 'height 0.3s ease, transform 0.3s ease',
        backgroundColor: '#282c34',
        overflowY: 'auto',
        overflowX: 'hidden',
        ...(isNarrowViewport && {
          margin: '0 8px',
          borderRadius: '12px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
        })
      }}
    >
      {children}
      
      <Fade in={showScrollButton}>
        <IconButton
          onClick={scrollToBottom}
          aria-label="scroll to bottom"
          sx={{
            position: 'absolute',
            bottom: '20px',
            right: '20px',
            bgcolor: 'primary.main',
            color: '#fff',
            '&:hover': {
              bgcolor: 'primary.dark',
              transform: 'translateY(-2px)'
            },
            transition: 'transform 0.2s ease',
            width: 40,
            height: 40,
            boxShadow: 2
          }}
        >
          <KeyboardArrowUpIcon />
        </IconButton>
      </Fade>
    </div>
  );
};

export default AdaptiveChatContainer;