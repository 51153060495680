import { makeApiCall } from "../api/apiCalls";

export const handleTaskRequest = async (
  taskType,
  llmChoice,
  languageChoice,
  summaryStyleChoice,
  setTaskStates,
  taskStateValues,
  exchangeRate,
  chatHistory // Pass chatHistory as well
) => {
  const {
    //currentTask,
    prompt,
    brainstormHistory,
    chatHistory: legacyChatHistory,
    sessionData,
    setSessionData,
    updateTokenData,
  } = taskStateValues;

  // Determine temperature:
  let temperature = 0.7;
  if (taskType === "Summary" || taskType === "Translation") {
    temperature = 0.2;
  }

  // Determine max_tokens
  let maxTokens = llmChoice === "Groq Llama-3 70B 8K" ? 2048 : 4096;

  let requestBody = {
    prompt: prompt,
    llm_model_choice: llmChoice,
    task_choice: taskType,
    language_choice: languageChoice,
    max_tokens: maxTokens,
    temperature: temperature,
    session_data: sessionData,
    usd_to_php_rate: exchangeRate.rate,
    rate_timestamp: exchangeRate.timestamp,
    chat_history: chatHistory // Include chat_history from messages
  };

  if (taskType === "Summary" && summaryStyleChoice) {
    requestBody.summary_style_choice = summaryStyleChoice;
  }

  // We now have a unified endpoint:
  const endpointToUse = "/unified_generate/";

  const data = await makeApiCall(
    endpointToUse,
    requestBody,
    taskType,
    setTaskStates.setBrainstormHistory,
    setTaskStates.setChatHistory,
    setTaskStates.setNonChatDraftHistory,
    setTaskStates.setIsNewChatSession,
    setTaskStates.setGeneratedText,
    setTaskStates.setTokenInfo,
    setTaskStates.isNewChatSession,
    prompt,
    brainstormHistory,
    legacyChatHistory,
    setSessionData,
    updateTokenData
  );

  return data;
};
