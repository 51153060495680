import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import apiClient from "../api/apiClient";

const OrganizationManagement = () => {
  const [organizations, setOrganizations] = useState([]);
  const [newOrgName, setNewOrgName] = useState("");
  const [selectedOrgType, setSelectedOrgType] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [organizationToDelete, setOrganizationToDelete] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchAllOrganizations();
  }, []);

  const fetchAllOrganizations = async () => {
    try {
      const response = await apiClient.get("/organizations/");
      setOrganizations(response.data);
    } catch (error) {
      console.error("Error fetching organizations:", error);
      setError("Failed to fetch organizations. Please try again.");
    }
  };

  const orgTypes = ["Partylist", "NDMO", "Others"];

  const addOrganization = async () => {
    // Clear any previous errors
    setError("");

    // Validate inputs
    if (!newOrgName.trim()) {
      setError("Organization name cannot be empty.");
      return;
    }
    if (!selectedOrgType) {
      setError("Please select an organization type.");
      return;
    }

    try {
      const organizationData = {
        name: newOrgName.trim(),
        org_type: selectedOrgType,
      };
      await apiClient.post("/admin/add-organization/", organizationData);
      setNewOrgName("");
      setSelectedOrgType("");
      fetchAllOrganizations();
    } catch (error) {
      console.error("Error adding organization:", error);
      setError("Failed to add organization. Please try again.");
    }
  };

  const deleteOrganization = async (organizationId) => {
    try {
      await apiClient.delete(`/admin/delete-organization/${organizationId}`);
      fetchAllOrganizations();
    } catch (error) {
      console.error("Error deleting organization:", error);
      setError("Failed to delete organization. Please try again.");
    }
  };

  const handleDeleteClick = (organizationId) => {
    setOrganizationToDelete(organizationId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (organizationToDelete) {
      deleteOrganization(organizationToDelete);
      setOrganizationToDelete(null);
      setDeleteDialogOpen(false);
    }
  };

  return (
    <Box sx={{ margin: 2 }}>
      <Typography variant="h6">Manage Organizations</Typography>
      {error && (
        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
          {error}
        </Alert>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          marginTop: 2,
          marginBottom: 2,
          "@media (min-width: 600px)": { flexDirection: "row" },
        }}
      >
        <TextField
          label="New Organization Name"
          variant="outlined"
          value={newOrgName}
          onChange={(e) => setNewOrgName(e.target.value)}
          error={!newOrgName.trim()}
          helperText={!newOrgName.trim() ? "Name is required" : ""}
          sx={{
            "& .MuiOutlinedInput-root": {
              backgroundColor: "#444",
              "& fieldset": {
                borderColor: "#666", // Default border color
              },
              "&:hover fieldset": {
                borderColor: "#888", // Hover border color
              },
              "&.Mui-focused fieldset": {
                borderColor: "#999", // Focused border color
              },
            },
            "& .MuiInputBase-input": {
              color: "white", // Text color
            },
            "& .MuiInputLabel-root": {
              color: "#999", // Label color
              "&.Mui-focused": {
                color: "#fff", // Focused label color
              },
            },
            "& .MuiFormHelperText-root": {
              color: "#999", // Helper text color
              // If you want error text to remain red, don't style it here
            },
          }}
        />
        <FormControl
          variant="outlined"
          sx={{
            minWidth: 120,
            "& .MuiInputLabel-root": {
              color: "#999", // Label color
              "&.Mui-focused": {
                color: "#fff", // Focused label color
              },
            },
          }}
          error={!selectedOrgType}
        >
          <InputLabel id="org-type-select-label" sx={{ color: "#999" }}>
            Org Type
          </InputLabel>
          <Select
            labelId="org-type-select-label"
            id="org-type-select"
            value={selectedOrgType}
            onChange={(e) => setSelectedOrgType(e.target.value)}
            label="Org Type"
            sx={{
              backgroundColor: "#444",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#666", // Default border color
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#888", // Hover border color
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#999", // Focused border color
              },
              "& .MuiSelect-select": {
                color: "white", // Text color
                fontSize: "14px",
              },
              "& .MuiSvgIcon-root": {
                color: "#999", // Dropdown arrow color
              },
            }}
          >
            {orgTypes.map((type) => (
              <MenuItem
                key={type}
                value={type}
                sx={{
                  "&:hover": {
                    backgroundColor: "#555", // MenuItem hover color
                  },
                }}
              >
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={addOrganization}
          disabled={!newOrgName.trim() || !selectedOrgType}
        >
          Add Organization
        </Button>
      </Box>
      <List>
        {organizations.map((org) => (
          <ListItem key={org.id} divider>
            <ListItemText
              primary={`${org.name} (${org.org_type})`}
              secondary={`ID: ${org.id}`}
              sx={{
                ".MuiListItemText-primary": { color: "white" },
                ".MuiListItemText-secondary": { color: "grey" },
              }}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDeleteClick(org.id)}
                sx={{ color: "darkgrey" }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this organization? This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={handleConfirmDelete} autoFocus color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OrganizationManagement;