// utils/metricsUtils.js

// Parse LLM metrics from token info string
export const extractLLMInfo = (tokenInfo) => {
    if (!tokenInfo) return null;
  
    const lines = tokenInfo.split("\n");
    let info = {
      model: "",
      promptTokens: 0,
      completionTokens: 0,
      totalTokens: 0,
      cost: 0,
      sessionTotalTokens: 0,
      sessionTotalCost: 0,
      modelsUsed: "",
    };
  
    for (const line of lines) {
      if (line.startsWith("Model:")) info.model = line.split(":")[1].trim();
      if (line.startsWith("Prompt tokens:"))
        info.promptTokens = parseInt(line.split(":")[1].trim(), 10);
      if (line.startsWith("Completion tokens:"))
        info.completionTokens = parseInt(line.split(":")[1].trim(), 10);
      if (line.startsWith("Total tokens:"))
        info.totalTokens = parseInt(line.split(":")[1].trim(), 10);
      if (line.startsWith("Cost (PHP):"))
        info.cost = parseFloat(line.split(":")[1].trim());
      if (line.startsWith("Session Total Tokens:"))
        info.sessionTotalTokens = parseInt(line.split(":")[1].trim(), 10);
      if (line.startsWith("Session Total Cost (PHP):"))
        info.sessionTotalCost = parseFloat(line.split(":")[1].trim());
      if (line.startsWith("Models Used:"))
        info.modelsUsed = line.split(":")[1].trim();
    }
  
    return info;
  };
  
  export const formatLLMMetrics = (tokenInfo, taskName) => {
    const info = extractLLMInfo(tokenInfo);
    return {
      task_name: taskName,
      model_used: info.model,
      operation_type: 'llm',  // Changed from 'LLM' to 'llm'
      resource_usage: info.totalTokens,
      cost_php: info.cost,
      metrics_data: {
        prompt_tokens: info.promptTokens,
        completion_tokens: info.completionTokens,
        models_used: info.modelsUsed
      }
    };
  };
  
  export const formatImageMetrics = (tokenData, operation) => {
    return {
      task_name: operation,
      model_used: tokenData.lastModelUsed || 'SD 1.5',
      operation_type: 'image',  // Changed from 'Image' to 'image'
      cost_php: tokenData.latestImageCostPHP,
      metrics_data: {
        inference_time: tokenData.lastImageInferenceTime,
        num_images: tokenData.lastImageNumImages || 1
      }
    };
  };
  
  export const formatAudioMetrics = (audioData, task, language) => {
    return {
      task_name: `Audio ${task}`,
      model_used: 'Whisper v3',
      operation_type: 'audio',  // Changed from 'Audio' to 'audio'
      cost_php: audioData.costPHP,
      metrics_data: {
        processing_time: audioData.processingTime,
        language: language,
        cost_usd: audioData.costUSD
      }
    };
  };