import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import ReactMarkdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import SaveFileModal from "./SaveFileModal";
import { convertToRtf } from "../api/apiCalls";

// ChatMessage subcomponent
const ChatMessage = ({ message, sender, sentTime, currentDocument }) => {
  const [copied, setCopied] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [filename, setFilename] = useState("");
  const isUser = sender === "You";

  // Function to detect if text contains Markdown
  const containsMarkdown = (text) => {
    // Check for common Markdown patterns
    const markdownPatterns = [
      /[*_]{1,2}[^*_]+[*_]{1,2}/, // bold or italic
      /^#+\s/, // headers
      /^\s*[-*+]\s/, // unordered lists
      /^\s*\d+\.\s/, // ordered lists
      /`[^`]+`/, // inline code
      /```[\s\S]*?```/, // code blocks
      /\[([^\]]+)\]\(([^)]+)\)/, // links
      /^\s*>/m, // blockquotes
      /\|[\s\S]+\|/, // tables
    ];

    return markdownPatterns.some((pattern) => pattern.test(text));
  };

  // Function to format plain text with preserved whitespace
  const formatPlainText = (text) => {
    return text.split("\n").map((line, i) => (
      <React.Fragment key={i}>
        {line || " "} {/* Use space for empty lines */}
        {i < text.split("\n").length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const renderMessage = () => {
    //const messageWithSpace = message + '\n\n\n';

    const hasMarkdown = containsMarkdown(message);

    if (hasMarkdown) {
      return (
        <ReactMarkdown
          components={{
            p: ({ node, ...props }) => (
              <Typography
                component="p"
                sx={{
                  m: 0,
                  color: "inherit",
                  fontSize: "0.970rem",
                  lineHeight: 1.25,
                }}
                {...props}
              />
            ),
            pre: ({ node, ...props }) => (
              <Box
                component="pre"
                sx={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                  fontFamily: "inherit",
                  fontSize: "0.875rem",
                  m: 0,
                  backgroundColor: isUser
                    ? "rgba(0,0,0,0.1)"
                    : "rgba(255,255,255,0.1)",
                  p: 1,
                  borderRadius: 1,
                }}
                {...props}
              />
            ),
            code: ({ node, inline, ...props }) =>
              inline ? (
                <Typography
                  component="code"
                  sx={{
                    backgroundColor: isUser
                      ? "rgba(0,0,0,0.1)"
                      : "rgba(255,255,255,0.1)",
                    p: 0.5,
                    borderRadius: 0.5,
                    fontFamily: "monospace",
                    fontSize: "0.875rem",
                  }}
                  {...props}
                />
              ) : (
                <Typography
                  component="code"
                  sx={{
                    display: "block",
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                    fontFamily: "monospace",
                    fontSize: "0.875rem",
                    m: 0,
                  }}
                  {...props}
                />
              ),
            // ... other markdown components remain the same ...
          }}
        >
          {message}
        </ReactMarkdown>
      );
    } else {
      // Render plain text with proper formatting
      return (
        <Typography
          sx={{
            m: 0,
            color: "inherit",
            fontSize: "0.970rem",
            lineHeight: 1.25,
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            "& br": {
              display: "block",
              content: '""',
              marginTop: "0.5em",
            },
          }}
        >
          {formatPlainText(message)}
        </Typography>
      );
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(message);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

  useEffect(() => {
    if (saveModalOpen) {
      // Get current timestamp in a filename-friendly format
      const timestamp = new Date()
        .toISOString()
        .replace(/[:]/g, "-")
        .split(".")[0];

      // Get PDF filename if available and clean it up
      let baseFilename = "chat-export";
      if (currentDocument?.filename) {
        // Remove .pdf extension and clean up the filename
        baseFilename = currentDocument.filename
          .replace(/\.pdf$/i, "") // Remove .pdf extension
          .replace(/[^a-zA-Z0-9-_]/g, "_") // Replace special chars with underscore
          .substring(0, 50); // Limit length
      }

      // Create default filename
      const defaultFilename = `${baseFilename}_${timestamp}`;

      setFilename(defaultFilename);
    }
  }, [saveModalOpen, currentDocument]);

  const handleSaveRtf = async () => {
    try {
      const timestamp = new Date()
        .toISOString()
        .replace(/[:]/g, "-")
        .split(".")[0];
      const baseFilename = currentDocument?.filename
        ? currentDocument.filename
            .replace(/\.pdf$/i, "")
            .replace(/[^a-zA-Z0-9-_]/g, "_")
            .substring(0, 50)
        : "chat-export";
      const defaultFilename = `${baseFilename}_${timestamp}`;

      // Function to detect if text contains Markdown
      const containsMarkdown = (text) => {
        const markdownPatterns = [
          /[*_]{1,2}[^*_]+[*_]{1,2}/, // bold or italic
          /^#+\s/, // headers
          /^\s*[-*+]\s/, // unordered lists
          /^\s*\d+\.\s/, // ordered lists
          /`[^`]+`/, // inline code
          /```[\s\S]*?```/, // code blocks
          /\[([^\]]+)\]\(([^)]+)\)/, // links
          /^\s*>/m, // blockquotes
          /\|[\s\S]+\|/, // tables
        ];
        return markdownPatterns.some((pattern) => pattern.test(text));
      };

      // Format plain text as markdown if needed
      const processedMessage = containsMarkdown(message)
        ? message
        : message
            .split(/\n\n+/) // Split into paragraphs (one or more blank lines)
            .map(
              (paragraph) =>
                paragraph
                  .split("\n")
                  .map((line) => line.trim())
                  .join("  \n") // Add markdown line breaks within paragraphs
            )
            .join("\n\n\n"); // Add two blank lines between paragraphs

      const blob = await convertToRtf(
        processedMessage,
        filename || defaultFilename
      );
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${filename || defaultFilename}.rtf`;
      link.click();
      window.URL.revokeObjectURL(url);
      setSaveModalOpen(false);
    } catch (err) {
      console.error("Failed to save RTF:", err);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isUser ? "flex-end" : "flex-start",
        mb: 2,
        width: "100%",
        px: { xs: 1, sm: 2 }, // Responsive padding
      }}
    >
      <Box
        sx={{
          width: { xs: "85%", sm: "90%", md: "90%" }, // Responsive width
          display: "flex",
          flexDirection: "column",
          alignItems: isUser ? "flex-end" : "flex-start",
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: "#888",
            mb: 0.5,
            fontSize: "0.75rem",
            px: 1, // Add padding to align with message
          }}
        >
          {`${sender} • ${sentTime}`}
        </Typography>
        <Paper
          elevation={1}
          sx={{
            width: "100%", // Take full width of parent
            px: 2.5,
            py: 1.5,
            pb: isUser ? 1.5 : 6,
            marginRight: "10px",
            backgroundColor: isUser ? "primary.main" : "#333",
            color: isUser ? "primary.contrastText" : "#fff",
            borderRadius: 2,
            position: "relative",
          }}
        >
          <Box
            sx={{
              pr: !isUser ? 4 : 0,
              width: "100%", // Ensure content takes full width
            }}
          >
            {renderMessage()}
          </Box>
          {!isUser && (
            <Box
              sx={{
                position: "absolute",
                right: 8,
                bottom: 8,
                display: "flex",
                alignItems: "center",
                gap: 1,
                bgcolor: "transparent",
              }}
            >
              <Tooltip title={copied ? "Copied!" : "Copy"}>
                <Box
                  component="span"
                  onClick={handleCopy}
                  sx={{
                    cursor: "pointer",
                    "& .icon-button": {
                      color: copied ? "#414541" : "#7890aa",
                      fontSize: "20px",
                      transition: "all 0.2s",
                      "&:hover": {
                        color: "#f17516",
                      },
                    },
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCopy}
                    className={`icon-button ${copied ? "highlighted" : ""}`}
                  />
                </Box>
              </Tooltip>
              <Tooltip title="Save as RTF">
                <Box
                  component="span"
                  onClick={() => setSaveModalOpen(true)}
                  sx={{
                    cursor: "pointer",
                    "& .icon-button": {
                      color: "#7890aa",
                      fontSize: "20px",
                      transition: "all 0.2s",
                      "&:hover": {
                        color: "#f17516",
                      },
                    },
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFloppyDisk}
                    className="icon-button"
                  />
                </Box>
              </Tooltip>
            </Box>
          )}
        </Paper>
      </Box>
      <SaveFileModal
        open={saveModalOpen}
        onClose={() => {
          setSaveModalOpen(false);
          setFilename("");
        }}
        onSave={handleSaveRtf}
        title="Save as RTF"
        filename={filename}
        onFilenameChange={(e) => setFilename(e.target.value)}
        placeholder="chat-export"
        fileExtension=".rtf"
      />
    </Box>
  );
};

// Main CustomChatInterface component
const CustomChatInterface = ({
  messages = [],
  isTyping = false,
  onSendMessage,
  disabled = false,
  sx = {},
  placeholder = "Type your message...",
  selectedDocument,
  emptyStateMessage = "Start a conversation in the chat box below.",
  alwaysShowSubmit = false,
}) => {
  const [inputMessage, setInputMessage] = useState("");
  const [saveChatModalOpen, setSaveChatModalOpen] = useState(false);
  const [chatFilename, setChatFilename] = useState("");
  const messagesContainerRef = useRef(null);
  const textareaRef = useRef(null);
  const latestMessageRef = useRef(null);

  useEffect(() => {
    if (saveChatModalOpen) {
      const timestamp = new Date()
        .toISOString()
        .replace(/[:]/g, "-")
        .split(".")[0];
      const baseFilename = selectedDocument?.filename
        ? selectedDocument.filename
            .replace(/\.pdf$/i, "")
            .replace(/[^a-zA-Z0-9-_]/g, "_")
            .substring(0, 50)
        : "chat-export";

      const defaultFilename = `${baseFilename}_${timestamp}`;
      setChatFilename(defaultFilename);
    }
  }, [saveChatModalOpen, selectedDocument]);

  const handleSaveChat = () => {
    const chatContent = messages
      .map((msg) => `[${msg.sentTime}] ${msg.sender}: ${msg.message}`)
      .join("\n");

    const element = document.createElement("a");
    const file = new Blob([chatContent], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `${chatFilename}.txt`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    URL.revokeObjectURL(element.href);
    setSaveChatModalOpen(false);
    setChatFilename("");
  };

  const scrollToLatestMessage = () => {
    if (latestMessageRef.current && messagesContainerRef.current) {
      const container = messagesContainerRef.current;
      const messageElement = latestMessageRef.current;
      const containerRect = container.getBoundingClientRect();
      const messageRect = messageElement.getBoundingClientRect();
      const relativeTop =
        messageRect.top - containerRect.top + container.scrollTop;
      container.scrollTo({
        top: relativeTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (messages.length > 0) {
      setTimeout(scrollToLatestMessage, 100);
    }
  }, [messages]);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(textarea.scrollHeight, 150)}px`;
    }
  }, [inputMessage]);

  // Add useEffect to handle focus after typing state changes
  useEffect(() => {
    // When typing state changes from true to false (response received)
    // and the textarea exists and isn't disabled, restore focus
    if (!isTyping && textareaRef.current && !disabled) {
      textareaRef.current.focus();
    }
  }, [isTyping, disabled]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputMessage.trim() && !isTyping && !disabled) {
      const messageToSend = inputMessage.trim();
      setInputMessage("");
      try {
        await onSendMessage(messageToSend);
        // After message is sent, restore focus to textarea
        if (textareaRef.current) {
          textareaRef.current.focus();
        }
      } catch (error) {
        console.error("Failed to send message:", error);
        setInputMessage(messageToSend);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <>
      <style>
        {`
        .chat-textarea {
          scrollbar-width: thin;
          scrollbar-color: #666 #444;
        }
        
        .chat-textarea::-webkit-scrollbar {
          width: 8px;
        }
        
        .chat-textarea::-webkit-scrollbar-track {
          background-color: #444;
        }
        
        .chat-textarea::-webkit-scrollbar-thumb {
          background-color: #666;
          border-radius: 4px;
        }
        
        .chat-textarea::-webkit-scrollbar-thumb:hover {
          background-color: #888;
        }
      `}
      </style>
      <Paper
        className="chat-interface-wrapper"
        sx={{
          bgcolor: "#333",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          overflow: "hidden", // Prevent content from spilling out
          height: "100%", // Take full height of parent
          maxHeight: "100%", // Ensure it doesn't exceed parent
          ...sx,
        }}
      >
        <Box
          ref={messagesContainerRef}
          className="messages-container"
          sx={{
            flex: 1,
            p: 2,
            overflowY: "auto",
            overflowX: "hidden",
            minHeight: 0, // Important! Allows flex item to scroll
            display: "flex",
            flexDirection: "column",
            "&::-webkit-scrollbar": { width: "8px" },
            "&::-webkit-scrollbar-track": { bgcolor: "#444" },
            "&::-webkit-scrollbar-thumb": {
              bgcolor: "#666",
              borderRadius: "4px",
              "&:hover": { bgcolor: "#888" },
            },
          }}
        >
          {messages.length === 0 ? (
            <Typography
              color="#888"
              align="center"
              sx={{
                mt: 2,
                fontSize: "14px",
                userSelect: "none", // Prevent text selection
              }}
            >
              {emptyStateMessage}
            </Typography>
          ) : (
            messages.map((msg, index) => (
              <Box
                key={index}
                ref={index === messages.length - 1 ? latestMessageRef : null}
                sx={{
                  width: "100%", // Ensure messages take full width
                  maxWidth: "100%", // Prevent overflow
                }}
              >
                <ChatMessage
                  message={msg.message}
                  sender={msg.sender}
                  sentTime={msg.sentTime}
                  currentDocument={selectedDocument}
                />
              </Box>
            ))
          )}
          {isTyping && (
            <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 1 }}>
              <LinearProgress sx={{ width: "100px" }} />
            </Box>
          )}
        </Box>

        <Box
          sx={{
            p: 2,
            borderTop: 1,
            borderColor: "#444",
            bgcolor: "#282c34",
            mt: "auto", // Push to bottom
            flexShrink: 0, // Prevent input area from shrinking
          }}
        >
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              border: "1px solid #444",
              borderRadius: "4px",
              padding: "8px",
              backgroundColor: "#333",
            }}
          >
            <textarea
              ref={textareaRef}
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder={placeholder}
              disabled={isTyping || disabled}
              className="chat-textarea"
              style={{
                border: "none",
                outline: "none",
                backgroundColor: "transparent",
                flex: 1,
                resize: "none",
                minHeight: "40px",
                maxHeight: "150px",
                color: "#fff",
                fontSize: "14px",
                scrollbarWidth: "thin" /* Firefox */,
                scrollbarColor: "#666 #444" /* Firefox */,
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#444",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#666",
                  borderRadius: "4px",
                  "&:hover": {
                    backgroundColor: "#888",
                  },
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                gap: 1,
                flexShrink: 0,
                flexDirection: { xs: "column", sm: "row" }, // Stack on mobile, row on larger screens
              }}
            >
              {(alwaysShowSubmit || messages.length > 0) && (
                <IconButton
                  type="submit"
                  color="primary"
                  disabled={
                    alwaysShowSubmit
                      ? false
                      : !inputMessage.trim() || isTyping || disabled
                  }
                  sx={{
                    height: 36,
                    width: 36,
                    bgcolor: "#333", // Changed from primary.main to #333
                    color: "#fff",
                    "&:hover": { bgcolor: "#444" }, // Darker on hover
                    "&.Mui-disabled": {
                      bgcolor: "#444",
                      color: "#666",
                    },
                  }}
                >
                  <SendIcon />
                </IconButton>
              )}
              <IconButton
                onClick={() => setSaveChatModalOpen(true)}
                disabled={disabled}
                sx={{
                  height: 36,
                  width: 36,
                  bgcolor: "#333", // Changed from primary.main to #333
                  color: "#fff",
                  "&:hover": { bgcolor: "#444" }, // Darker on hover
                  "&.Mui-disabled": {
                    bgcolor: "#444",
                    color: "#666",
                  },
                }}
              >
                <SaveIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <SaveFileModal
          open={saveChatModalOpen}
          onClose={() => {
            setSaveChatModalOpen(false);
            setChatFilename("");
          }}
          onSave={handleSaveChat}
          title="Save Chat History"
          filename={chatFilename}
          onFilenameChange={(e) => setChatFilename(e.target.value)}
          placeholder="chat_history"
          fileExtension=".txt"
        />
      </Paper>
    </>
  );
};

export default CustomChatInterface;
