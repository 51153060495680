import React, { useState } from "react";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  FormControl,
  Paper,
  Stack,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";

const TimeRangeSelector = ({ startDate, endDate, onChange }) => {
  const [selectedRange, setSelectedRange] = useState("7d");

  const handleRangeChange = (event, newRange) => {
    if (!newRange) return; // Prevent null selection

    setSelectedRange(newRange);
    const now = new Date();
    let start = new Date();

    switch (newRange) {
      case "1d":
        start = new Date(now.setHours(0, 0, 0, 0));
        break;
      case "7d":
        start = new Date(now.setDate(now.getDate() - 7));
        break;
      case "30d":
        start = new Date(now.setDate(now.getDate() - 30));
        break;
      case "custom":
        // Keep existing dates for custom selection
        return;
      default:
        return;
    }

    onChange({
      startDate: start,
      endDate: new Date(),
    });
  };

  const handleDateChange = (field) => (newValue) => {
    if (newValue && !isNaN(newValue)) {
      setSelectedRange("custom");
      onChange({
        startDate: field === "startDate" ? newValue : startDate,
        endDate: field === "endDate" ? newValue : endDate,
      });
    }
  };

  return (
    <Stack
      spacing={2}
      sx={{
        width: "100%",
      }}
    >
      <Paper sx={{ bgcolor: "#444", width: "fit-content" }}>
        <ToggleButtonGroup
          value={selectedRange}
          exclusive
          onChange={handleRangeChange}
          sx={{
            height: "40px",
            flexWrap: "wrap",
            "& .MuiToggleButton-root": {
              height: "40px",
              padding: "0 12px",
              minWidth: "auto",
              textTransform: "none",
              color: "#999",
              "&.Mui-selected": {
                color: "white",
                bgcolor: "#666",
                "&:hover": {
                  bgcolor: "#777",
                },
              },
              "&:hover": {
                bgcolor: "#555",
              },
            },
          }}
        >
          <ToggleButton value="1d">Today</ToggleButton>
          <ToggleButton value="7d">7 Days</ToggleButton>
          <ToggleButton value="30d">30 Days</ToggleButton>
          <ToggleButton value="custom">Custom</ToggleButton>
        </ToggleButtonGroup>
      </Paper>
      {(selectedRange === "custom" || selectedRange === null) && (
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          <FormControl
            sx={{
              minWidth: 200,
              maxWidth: 300, // Added maxWidth
              flex: { xs: "1 1 100%", sm: "1 1 auto" },
            }}
          >
            <DateTimePicker
              label="From Date"
              value={startDate}
              onChange={handleDateChange("startDate")}
              sx={{
                bgcolor: "#444",
                "& .MuiInputBase-input": { color: "white" },
                "& .MuiInputLabel-root": { color: "#999" },
                "& .MuiOutlinedInput-notchedOutline": { borderColor: "#666" },
              }}
            />
          </FormControl>
          <FormControl
            sx={{
              minWidth: 200,
              maxWidth: 300, // Added maxWidth
              flex: { xs: "1 1 100%", sm: "1 1 auto" },
            }}
          >
            <DateTimePicker
              label="To Date"
              value={endDate}
              onChange={handleDateChange("endDate")}
              sx={{
                bgcolor: "#444",
                "& .MuiInputBase-input": { color: "white" },
                "& .MuiInputLabel-root": { color: "#999" },
                "& .MuiOutlinedInput-notchedOutline": { borderColor: "#666" },
              }}
            />
          </FormControl>
        </Box>
      )}
    </Stack>
  );
};

export default TimeRangeSelector;
