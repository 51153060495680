import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

const TopStatsCard = ({ title, items }) => (
  <Paper sx={{ 
    p: 2, 
    bgcolor: "#444", 
    color: "white",
    flex: 1
  }}>
    <Typography variant="subtitle2" sx={{ color: "inherit", mb: 2 }}>
      {title}
    </Typography>
    {items.map((item, index) => (
      <Box 
        key={index} 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          mb: 1,
          gap: 1
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          gap: 1,
          flex: 1,
          minWidth: 0
        }}>
          <Typography sx={{ 
            color: "#999",
            flexShrink: 0
          }}>
            #{index + 1}
          </Typography>
          <Typography sx={{ 
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            minWidth: 0,
            flex: 1
          }}>
            {item.email || item.name}
          </Typography>
        </Box>
        <Typography sx={{ 
          color: "#999",
          flexShrink: 0,
          pl: 1
        }}>
          {item.count.toLocaleString()} {item.count === 1 ? 'call' : 'calls'}
        </Typography>
      </Box>
    ))}
  </Paper>
);

const TopStatisticsSection = ({ topStats }) => {
  if (!topStats) return null;

  return (
    <Box sx={{ 
      display: "flex", 
      flexDirection: { xs: 'column', sm: 'row' },
      gap: 2,
      width: '100%'
    }}>
      <TopStatsCard
        title="Top Users by Usage"
        items={topStats.top_users}
      />
      <TopStatsCard
        title="Most Used Tasks"
        items={topStats.top_tasks}
      />
      <TopStatsCard
        title="Most Used Models"
        items={topStats.top_models}
      />
    </Box>
  );
};

export default TopStatisticsSection;