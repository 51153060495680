import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography,
} from '@mui/material';
import { Warning } from '@mui/icons-material';

const UploadWarningDialog = ({ 
  isOpen, 
  onClose, 
  onProceed, 
  onSaveChat 
}) => {
  return (
    <Dialog 
      open={isOpen} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          bgcolor: '#282c34',
          color: '#fff',
          borderRadius: 1,
          border: '1px solid #444',
          '& .MuiDialogTitle-root': {
            borderBottom: '1px solid #444',
          },
          '& .MuiDialogContent-root': {
            padding: '20px',
          },
          '& .MuiDialogActions-root': {
            borderTop: '1px solid #444',
            padding: '16px',
          }
        }
      }}
    >
      <DialogTitle 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          color: '#fff',
          fontSize: '1.1rem',
          fontWeight: 500,
          padding: '16px 20px',
        }}
      >
        <Warning sx={{ 
          color: 'warning.main',
          fontSize: '1.5rem' 
        }} />
        Save Current Chat?
      </DialogTitle>

      <DialogContent>
        <Typography sx={{ 
          color: '#fff',
          fontSize: '0.95rem',
          lineHeight: 1.5 
        }}>
          You have an existing chat history. Would you like to save it before uploading new documents? 
          The current chat history will be cleared when new documents are uploaded.
        </Typography>
      </DialogContent>

      <DialogActions sx={{ 
        padding: '16px 20px',
        gap: 1 
      }}>
        <Button 
          onClick={onClose}
          sx={{
            color: '#888',
            '&:hover': {
              color: '#fff',
              bgcolor: 'rgba(255, 255, 255, 0.1)'
            }
          }}
        >
          Cancel Upload
        </Button>
        <Button 
          onClick={onSaveChat}
          variant="contained"
          sx={{
            bgcolor: 'primary.main',
            color: '#fff',
            '&:hover': {
              bgcolor: 'primary.dark'
            }
          }}
        >
          Save Chat & Proceed
        </Button>
        <Button 
          onClick={onProceed}
          variant="contained"
          sx={{
            bgcolor: 'error.main',
            color: '#fff',
            '&:hover': {
              bgcolor: 'error.dark'
            }
          }}
        >
          Proceed Without Saving
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadWarningDialog;