import React from "react";
import UserManagement from "./UserManagement";
import OrganizationManagement from "./OrganizationManagement";
import MetricsManagement from "./MetricsManagement";
import Tabs, { Tab } from "./Tabs"; // Use the main Tabs component

const AdminDashboard = () => {
  return (
    <div>
      <Tabs>
        <Tab label="Metrics">
          <MetricsManagement />
        </Tab>
        <Tab label="Users">
          <UserManagement />
        </Tab>
        <Tab label="Organizations">
          <OrganizationManagement />
        </Tab>
      </Tabs>
    </div>
  );
};

export default AdminDashboard;
