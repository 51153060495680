import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button
} from '@mui/material';

const SaveFileModal = ({
  open,
  onClose,
  onSave,
  title,
  filename,
  onFilenameChange,
  placeholder,
  helperText,
  fileExtension = '.txt'  // default extension
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          bgcolor: '#282c34',
          color: '#fff',
          borderRadius: 1,
          border: '1px solid #444',
          minWidth: '400px',
          '& .MuiDialogTitle-root': {
            borderBottom: '1px solid #444',
          },
          '& .MuiDialogContent-root': {
            padding: '20px',
          },
          '& .MuiDialogActions-root': {
            borderTop: '1px solid #444',
            padding: '16px',
          }
        }
      }}
    >
      <DialogTitle sx={{ 
        color: '#fff',
        fontSize: '1.1rem',
        fontWeight: 500
      }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Filename"
          fullWidth
          value={filename}
          onChange={onFilenameChange}
          placeholder={placeholder}
          helperText={helperText || `${fileExtension} will be automatically added`}
          sx={{
            '& .MuiOutlinedInput-root': {
              color: '#fff',
              '& fieldset': {
                borderColor: '#444',
              },
              '&:hover fieldset': {
                borderColor: '#666',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#90caf9',
              }
            },
            '& .MuiInputLabel-root': {
              color: '#888',
              '&.Mui-focused': {
                color: '#90caf9',
              }
            },
            '& .MuiFormHelperText-root': {
              color: '#666'
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose}
          sx={{
            color: '#888',
            '&:hover': {
              color: '#fff',
              bgcolor: 'rgba(255, 255, 255, 0.1)'
            }
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={onSave}
          variant="contained"
          sx={{
            bgcolor: 'primary.main',
            color: '#fff',
            '&:hover': {
              bgcolor: 'primary.dark'
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveFileModal;