import React, { useState, useEffect } from "react";
import { useExchangeRate } from "../ExchangeRateProvider";
import { useSessionData } from "../SessionDataProvider";
import { useTask } from "../TaskContext";
import { useFile } from "../FileContext";
import PdfFileUploadButton from "../components/PdfFileUploadButton";
import TextInput from "../components/TextInput";
import PdfViewer from "../components/PdfViewer";
import MyTextArea from "../components/MyTextArea";
import SaveFileModal from "../components/SaveFileModal";
import MySlider from "../components/Slider";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import { getApiBaseUrl } from "../api/apiClient.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWandMagicSparkles,
  faSpinner,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";

import {
  uploadPdf,
  summarizePdf,
  summarizePdfDoc,
  fetchAndUploadPdf,
} from "../api/apiCalls";

const UnifiedPdfSummaryTab = ({
  outputHistory,
  setOutputHistory,
  fileUrl,
  setFileUrl,
  numPages,
  setNumPages,
  pageNumber,
  setPageNumber,
  onTokenCountUpdate,
  scale,
  setScale,
  summaryMode,
  setSummaryMode,
  useAdvancedSummary = false, // New prop to toggle between simple and advanced summarization
}) => {
  const [pageRange, setPageRange] = useState("");
  const [chunkSize, setChunkSize] = useState(8);
  const { exchangeRate } = useExchangeRate();
  const { sessionData, setSessionData } = useSessionData();
  const { fileState, setFileState } = useFile();
  const [isLoading, setIsLoading] = useState(false);
  const [summary, setSummary] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [clickedIcon, setClickedIcon] = useState({ save: false });
  const { setUsageTask, updateTaskUsage } = useTask();
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [saveFilename, setSaveFilename] = useState("");

  const isValidUrl = (urlString) => {
    try {
      new URL(urlString);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleFetchAndUpload = async () => {
    setIsLoading(true);
    if (!isValidUrl(pdfUrl)) {
      setErrorMessage(
        "The URL provided is invalid. Please enter a correct URL."
      );
      setIsError(true);
      setIsLoading(false);
      return;
    }

    try {
      const result = await fetchAndUploadPdf(pdfUrl);
      setFileState({
        identifier: pdfUrl,
        path: result.path,
      });
      const baseApiUrl = getApiBaseUrl();
      setFileUrl(`${baseApiUrl}/${result.path}`);
      setSummary("");
    } catch (error) {
      console.error("Error in fetching and uploading PDF:", error);
      setErrorMessage("Failed to fetch and upload PDF. Please try again.");
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (file) => {
    setIsLoading(true);
    try {
      const uploadResponse = await uploadPdf(file);
      setFileState({
        identifier: file.name,
        path: uploadResponse.path,
      });
      setFileUrl(URL.createObjectURL(file));
      setSummary("");
      setPdfUrl("");
    } catch (error) {
      console.error("Error during file upload:", error);
      setErrorMessage("Failed to upload PDF. Please try again.");
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const appendToOutputHistory = (newSummary) => {
    setOutputHistory((prevHistory) => {
      const existingSummaryCount = (prevHistory.match(/Title:/g) || []).length;
      const newSummaryNumber = existingSummaryCount + 1;
      const formattedSummary = `${newSummaryNumber}. ${newSummary}`;
      return prevHistory + formattedSummary + "\n\n";
    });
  };

  const handleAdvancedSummarize = async () => {
    setIsLoading(true);
    try {
      const usageTaskName = "Summarize PDF";
      setUsageTask(usageTaskName);
      updateTaskUsage(usageTaskName);

      const sessionDataJson = JSON.stringify(sessionData);
      const usdToPhpRate = exchangeRate.rate;

      const summaryResponse = await summarizePdf(
        fileState.path,
        chunkSize,
        pageRange,
        sessionDataJson,
        usdToPhpRate
      );

      const {
        segment_summary,
        final_summary,
        token_count,
        updated_session_data,
      } = summaryResponse;

      setSessionData(updated_session_data);
      const combined = `${final_summary}\n\nSegment Summaries:\n${segment_summary}`;
      setSummary(combined);
      appendToOutputHistory(combined);

      if (onTokenCountUpdate) {
        onTokenCountUpdate(token_count);
      }
    } catch (error) {
      console.error("Error during advanced summarization:", error);
      setErrorMessage("Failed to summarize PDF. Please try again.");
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSimpleSummarize = async () => {
    setIsLoading(true);
    try {
      const summaryResponse = await summarizePdfDoc(fileState.path, pageRange);
      setSummary(summaryResponse.summary);
      appendToOutputHistory(summaryResponse.summary);
    } catch (error) {
      console.error("Error during simple summarization:", error);
      setErrorMessage("Failed to summarize PDF. Please try again.");
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (saveModalOpen) {
      const lines = outputHistory.split("\n");
      const documentTitle = lines[0] || "Document";
      const timestamp = new Date().toISOString().replace(/[-T:.]/g, "");
      const defaultFilename = `${documentTitle}_${timestamp}`;
      setSaveFilename(defaultFilename.replace(".txt", "")); // Remove .txt as it's added by modal
    }
  }, [saveModalOpen, outputHistory]);

  // Modify handleSave to keep existing logic
  const handleSave = () => {
    setClickedIcon({ ...clickedIcon, save: true });
    setTimeout(() => setClickedIcon({ ...clickedIcon, save: false }), 1000);

    const lines = outputHistory.split("\n");
    const documentTitle = lines[0] || "Document";
    const timestamp = new Date().toISOString().replace(/[-T:.]/g, "");

    const blob = new Blob([outputHistory], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${saveFilename || `${documentTitle}_${timestamp}`}.txt`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);

    setSaveModalOpen(false);
    setSaveFilename("");
  };

  const zoomIn = () => setScale((scale) => scale * 1.2);
  const zoomOut = () => setScale((scale) => Math.max(scale * 0.8, 0.5));

  return (
    <Grid container spacing={2} className="pdf-summary-tab-container">
      <Grid item xs={12} md={6}>
        <div className="pdf-summary-tab-left">
          {isLoading && <LinearProgress />}

          {/* Mode Selector */}
          <div
            className="mode-selector"
            style={{
              padding: "12px",
              marginBottom: "-20px",
              backgroundColor: "#282c34",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              gap: "32px",
            }}
          >
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
                color: summaryMode === "simple" ? "white" : "#9CA3AF",
              }}
            >
              <input
                type="radio"
                name="summaryMode"
                checked={summaryMode === "simple"}
                onChange={() => setSummaryMode("simple")}
                style={{ cursor: "pointer" }}
              />
              <div>
                <div style={{ fontWeight: 500 }}>Outline</div>
                <div style={{ fontSize: "0.875rem", color: "#9CA3AF" }}>
                  For shorter documents
                </div>
              </div>
            </label>

            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
                color: summaryMode === "advanced" ? "white" : "#9CA3AF",
              }}
            >
              <input
                type="radio"
                name="summaryMode"
                checked={summaryMode === "advanced"}
                onChange={() => setSummaryMode("advanced")}
                style={{ cursor: "pointer" }}
              />
              <div>
                <div style={{ fontWeight: 500 }}>By Segment</div>
                <div style={{ fontSize: "0.875rem", color: "#9CA3AF" }}>
                  For very long documents
                </div>
              </div>
            </label>
          </div>

          <div className="fab-container">
            {isError && (
              <Alert severity="error" onClose={() => setIsError(false)}>
                {errorMessage}
              </Alert>
            )}
            <div className="textarea-container">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleFetchAndUpload();
                }}
              >
                <input
                  type="text"
                  value={pdfUrl}
                  onChange={(e) => {
                    setPdfUrl(e.target.value);
                    if (isError) {
                      setIsError(false);
                      setErrorMessage("");
                    }
                  }}
                  placeholder="Enter PDF url or upload a PDF file"
                  className="pdf-url-input"
                  style={{
                    width: "90%",
                    backgroundColor: "#333",
                    color: "white",
                    fontSize: "14px",
                    borderRadius: "8px",
                    marginLeft: "2%",
                    height: "25px",
                    paddingLeft: "6px",
                    border: "1px solid #666",
                  }}
                />
                <button type="submit" className="enter-button">
                  Enter
                </button>
              </form>
            </div>
            <Tooltip title="Select PDF from your local files">
              <span>
                <PdfFileUploadButton onFileUpload={handleFileUpload} />
              </span>
            </Tooltip>
            <Tooltip title="Enter page range (e.g., 1-4) for partial summary. Leave blank to summarize whole document.">
              <span>
                <MyTextArea
                  placeholder="Enter page range"
                  width="130px"
                  backgroundColor="#333"
                  color="white"
                  fontSize="14px"
                  textAlign="center"
                  onChange={(e) => setPageRange(e.target.value)}
                />
              </span>
            </Tooltip>
            {summaryMode === "advanced" && (
              <Tooltip title="Lower for more detailed summary, higher for less">
                <span>
                  <MySlider
                    label="Pages per Segment"
                    step={1}
                    defaultValue={8}
                    onChange={(value) => setChunkSize(value)}
                    min={2}
                    max={20}
                    style={{ width: "100%" }}
                  />
                </span>
              </Tooltip>
            )}
            <div className="icon-large-container">
              {isLoading ? (
                <button
                  className="pdf-icon-button"
                  disabled
                  style={{ cursor: "not-allowed" }}
                >
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="icon-button-large fa-spin"
                  />
                </button>
              ) : (
                <Tooltip title="Summarize PDF">
                  <button
                    className="pdf-icon-button"
                    onClick={
                      summaryMode === "advanced"
                        ? handleAdvancedSummarize
                        : handleSimpleSummarize
                    }
                  >
                    <FontAwesomeIcon
                      icon={faWandMagicSparkles}
                      className="icon-button-large"
                    />
                  </button>
                </Tooltip>
              )}
              <Tooltip title="Save summary">
                <button
                  className={`pdf-icon-button ${
                    clickedIcon.save ? "highlighted" : ""
                  }`}
                  onClick={() => setSaveModalOpen(true)}
                >
                  <FontAwesomeIcon
                    icon={faFloppyDisk}
                    className="icon-button-large"
                  />
                </button>
              </Tooltip>

              <SaveFileModal
                open={saveModalOpen}
                onClose={() => {
                  setSaveModalOpen(false);
                  setSaveFilename("");
                }}
                onSave={handleSave}
                title="Save Summary"
                filename={saveFilename}
                onFilenameChange={(e) => setSaveFilename(e.target.value)}
                placeholder="summary_export"
                helperText=".txt will be automatically added"
                fileExtension=".txt"
              />
            </div>
          </div>
          <div>
            {fileUrl && (
              <PdfViewer
                file={fileUrl}
                pageNumber={pageNumber}
                numPages={numPages}
                setPageNumber={setPageNumber}
                scale={scale}
                setScale={setScale}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                zoomIn={zoomIn}
                zoomOut={zoomOut}
              />
            )}
          </div>
        </div>
      </Grid>

      <Grid item xs={12} md={6}>
        <div className="pdf-summary-tab-right">
          <TextInput
            label="Summary"
            value={summary}
            placeholder="PDF summary will appear here"
            className="input-container generated-text-container"
            tabContext="PdfSummaryTab"
            readOnly={true}
          />
          <TextInput
            label="Output History"
            placeholder="PDF summaries for this session"
            readOnly={true}
            value={outputHistory}
            className="input-container draft-history-container"
            tabContext="PdfSummaryTab"
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default UnifiedPdfSummaryTab;
