import React, { useState, useRef, useEffect } from "react";
import { Document, Page } from "react-pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

const BUFFER_PAGES = 2; // Number of pages to keep preloaded around the visible area

const PdfViewer = ({ file }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); // Current page
  const [scale, setScale] = useState(0.9); // Zoom scale for pages
  const [visiblePageNumbers, setVisiblePageNumbers] = useState([]);
  const viewerRef = useRef();
  const pageHeights = useRef({});

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setVisiblePageNumbers([1]); // Initially load only the first page
  };

  const onLoadSuccessPage = (pageInfo) => {
    const { height } = pageInfo;
    pageHeights.current[pageInfo.pageNumber] = height;
  };

  const zoomIn = () => setScale((scale) => scale * 1.2);
  const zoomOut = () => setScale((scale) => Math.max(scale * 0.8, 0.5));

  const calculateVisiblePages = () => {
    const viewer = viewerRef.current;
    const scrollTop = viewer.scrollTop;
    let accumulatedHeight = 0;
    let currentPage = 1;

    for (let i = 1; i <= numPages; i++) {
      accumulatedHeight += pageHeights.current[i] || 1000; // Default height if not known
      if (scrollTop <= accumulatedHeight) {
        currentPage = i;
        break;
      }
    }

    setPageNumber(currentPage); // Set the current page based on scroll position

    // Calculate the range of pages to preload
    const endPage = Math.min(numPages, currentPage + BUFFER_PAGES);
    const startPage = Math.max(1, currentPage - BUFFER_PAGES);

    // Set buffer around visible pages
    const bufferStart = Math.max(1, startPage - BUFFER_PAGES);
    const bufferEnd = Math.min(numPages, endPage + BUFFER_PAGES);

    // Use requestAnimationFrame for state update
    requestAnimationFrame(() => {
      setVisiblePageNumbers(
        Array.from(
          { length: bufferEnd - bufferStart + 1 },
          (_, i) => bufferStart + i
        )
      );
    });
  };

  const handleScroll = _.debounce(calculateVisiblePages, 100); // Debounce the scroll event

  useEffect(() => {
    const viewer = viewerRef.current;
    viewer.addEventListener("scroll", handleScroll);
    return () => {
      viewer.removeEventListener("scroll", handleScroll);
      handleScroll.cancel(); // Cancel any pending debounced calls
    };
    // Include handleScroll in the dependency array
  }, [handleScroll]);

  return (
    <>
      <div className="viewer-container">
        <div
          className="pdf-controls"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "10%",
            marginRight: "10%",
          }}
        >
          <div className="icon-large-container">
            <FontAwesomeIcon
              icon={faMagnifyingGlassMinus}
              onClick={zoomOut}
              className="icon-button-large"
            />
            <FontAwesomeIcon
              icon={faMagnifyingGlassPlus}
              onClick={zoomIn}
              className="icon-button-large"
            />
          </div>
          <div style={{ display: "flex", alignItems: "center", color: "#888" }}>
            <p>
              Page {pageNumber} of {numPages}
            </p>
          </div>
        </div>

        <div
          className="pdf-display"
          ref={viewerRef}
          style={{ overflowY: "auto" }}
        >
          {file && (
            <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from({ length: numPages }, (_, index) => {
                const pageNum = index + 1;
                if (visiblePageNumbers.includes(pageNum)) {
                  return (
                    <Page
                      key={pageNum}
                      pageNumber={pageNum}
                      scale={scale}
                      onLoadSuccess={(pageInfo) => onLoadSuccessPage(pageInfo)}
                    />
                  );
                } else {
                  // Render placeholder for off-screen pages
                  return (
                    <div
                      key={pageNum}
                      style={{ height: pageHeights.current[pageNum] || 1000 }}
                    >
                      {/* Optionally, you could add a "Page Loading..." text or similar */}
                    </div>
                  );
                }
              })}
            </Document>
          )}
        </div>
      </div>
    </>
  );
};

export default PdfViewer;
